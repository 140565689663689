import React, { useState, useEffect } from "react";
import { FiMenu } from "react-icons/fi";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLink,
} from "./NavbarElements";
import MiskinLogoAnimated from "./logos/logoAnimated.svg";
import styled from "styled-components";

const Anchor = styled.a`
  font-family: DINProMedium;
  letter-spacing: 0.02em;
  color: #efefef;
  text-decoration: none;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.1rem;

  &:hover {
    color: #f94819;
    transition: 0.2s ease-in-out;
  }
`;

const Navbar = ({ isOpen, toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 10) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  return (
    <>
      <Nav scrollNav={scrollNav}>
        <NavbarContainer scrollNav={scrollNav}>
          <NavLogo to="/">
            <img src={MiskinLogoAnimated} alt="Logo" width="100%" />
          </NavLogo>
          <MobileIcon isOpen={isOpen} onClick={toggle}>
            <FiMenu />
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavLink to="/services">SERVICES</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/equipments">EQUIPMENTS</NavLink>
            </NavItem>
            <NavItem>
              <Anchor href="//www.vimeo.com/studyomiskin" target="_blank">
                WORKS
              </Anchor>
            </NavItem>
            <NavItem>
              <NavLink to="/about">ABOUT</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/contact">CONTACT</NavLink>
            </NavItem>
          </NavMenu>
        </NavbarContainer>
      </Nav>
    </>
  );
};

export default Navbar;
