import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
  position: relative;
`;

export const PurpleCircle = styled.div`
  border-radius: 50%;
  aspect-ratio: 1 / 1;
  width: 26%;
  /* max-width: 250px; */
  transform: translate(120%, 0);
  display: inline-block;
  position: absolute;
  background: transparent;
  border: none;
  mix-blend-mode: screen;
  /* box-shadow: 0 0 10px -5px #7d0df1, inset 0 0 10px -5px #7d0df1; */
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    padding: 2px;
    border-radius: 50%;
    transform: rotate(0);
    filter: blur(1px);
    background: linear-gradient(-90deg, #7d0df1, #efefef),
      linear-gradient(0deg, #ffffff, #ffffff);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
`;

export const BlueCircle = styled.div`
  /* box-shadow: 0 0 10px -5px #0dcef1, inset 0 0 10px -5px #0dcef1; */
  border-radius: 50%;
  aspect-ratio: 1 / 1;
  width: 26%;
  /* max-width: 250px; */
  transform: translate(-120%, 0);
  display: inline-block;
  position: absolute;
  background: transparent;
  border: none;
  mix-blend-mode: screen;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    padding: 2px;
    border-radius: 50%;
    transform: rotate(0);
    filter: blur(1px);
    background: linear-gradient(-90deg, #0dcef1, #efefef),
      linear-gradient(0deg, #ffffff, #ffffff);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
`;

export const GreenCircle = styled.div`
  /* box-shadow: 0 0 10px -5px #0deb69, inset 0 0 10px -5px #0deb69; */
  border-radius: 50%;
  aspect-ratio: 1 / 1;
  width: 26%;
  /* max-width: 250px; */
  transform: translate(0, 0);
  display: inline-block;
  position: absolute;
  background: transparent;
  border: none;
  mix-blend-mode: screen;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    padding: 2px;
    border-radius: 50%;
    transform: rotate(0);
    filter: blur(1px);
    background: linear-gradient(-90deg, #0deb69, #efefef),
      linear-gradient(0deg, #ffffff, #ffffff);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
`;
