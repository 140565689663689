import React from "react";
import styled from "styled-components";

const Container = styled.div`
  height: 80vh;
`;

const TextContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SvgContainer = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  user-select: none;
`;

const AboutScreen = () => {
  return (
    <Container className="panel">
      <TextContainer>
        <TextWrapper></TextWrapper>
        <SvgContainer className="pin-screen">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            style={{
              width: "100%",
              aspectRatio: "2/1",
            }}
          >
            <defs>
              <linearGradient id="gradient" y1="1" y2="1">
                <stop stopColor="#f94819" offset="0.5" />
                <stop stopColor="#ffc8b9" offset="1" />
              </linearGradient>
            </defs>
            <g>
              <text
                style={{
                  fontFamily: "DINProMedium",
                  letterSpacing: "0.1em",
                  fontSize: "6.4vw",
                }}
                id="text2"
                y="40%"
                x="30%"
                fill="#efefef"
              >
                HOW WE BECAME
              </text>
              <text
                className="drop-shadow"
                style={{
                  fontFamily: "DINProMedium",
                  letterSpacing: "0em",
                  fontSize: "14vw",
                  filter: "drop-shadow(0px 0px 80px #f94819)",
                }}
                id="text"
                y="68%"
                x="16%"
                strokeWidth="5"
                stroke="url(#gradient)"
                fill="transparent"
              >
                STÜDYO MİSKİN
              </text>
            </g>
          </svg>
        </SvgContainer>
      </TextContainer>
    </Container>
  );
};

export default AboutScreen;
