import React from "react";
import styled from "styled-components";
import Circles from "../Circles";
import ShapeShift from "../ShapeShifter";
import HearingIcon from "@mui/icons-material/Hearing";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import TouchAppOutlinedIcon from "@mui/icons-material/TouchAppOutlined";
import { NavLink as Link } from "react-router-dom";

const Container = styled.section`
  width: 100%;
  height: 100vh;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 6% 0 6%;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TopContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TopLeftContainer = styled.div`
  width: 100%;
  flex: 1.1;
`;

const TopRightContainer = styled.div`
  width: 100%;
  height: 100%;
  flex: 0.9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3vh;
`;

const TopTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 3vw;
`;

const TopText = styled.p`
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 100;
  color: #efefef;
  font-size: max(1.4vw, 1.2rem);
`;

const NavLink = styled(Link)``;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Button = styled.button`
  padding: 1vw 1.7vw;
  font-size: max(1.3vw, 1rem);
  color: #efefef;
  border-radius: 100px;
  border: 1px solid #efefef;
  background: transparent;

  &:hover {
    animation: glow 0.2s linear both;
  }

  @keyframes glow {
    to {
      box-shadow: 0 0 50px -25px #efefef;
      color: #0d0f0f;
      background: #efefef;
    }
  }
`;

const BottomContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BottomLeftContainer = styled.div`
  width: 100%;
  height: 100%;
  flex: 0.9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3vh;
`;

const BottomTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 5vw;
`;

const BottomText = styled.h1`
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 100;
  color: #efefef;
  font-size: max(1.4vw, 1.2rem);
  text-align: end;
  line-height: 1.5;
`;

const BottomRightContainer = styled.div`
  width: 100%;
  flex: 1.1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TextGradient = styled.span`
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 100;
  font-size: max(1.4vw, 1.2rem);
  background-image: linear-gradient(
    to right,
    ${(props) => props.color} 0%,
    ${(props) => props.secondColor} 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const HomeScreen2 = () => {
  return (
    <>
      <Container className="panel">
        <Wrapper>
          <TopContainer>
            <TopLeftContainer>
              <Circles />
            </TopLeftContainer>
            <TopRightContainer>
              <TopTextContainer>
                <TopText>
                  Stüdyo Miskin is a production company providing <br />
                  <TextGradient color="#0dcef1" secondColor="#97dce8">
                    <HearingIcon
                      style={{
                        margin: "-1% 4px 0 0",
                        fontSize: "max(1.6vw, 1rem)",
                        color: "#0dcef1",
                      }}
                    />
                    Auditory
                  </TextGradient>{" "}
                  <TextGradient color="#0deb69" secondColor="#9bd7b4">
                    <VisibilityOutlinedIcon
                      style={{
                        margin: "-1% 4px 0 0",
                        fontSize: "max(1.6vw, 1rem)",
                        color: "#0deb69",
                      }}
                    />
                    Visual
                  </TextGradient>{" "}
                  <TextGradient color="#7d0df1" secondColor="#bb8deb">
                    <TouchAppOutlinedIcon
                      style={{
                        margin: "-1% 4px 0 0",
                        fontSize: "max(1.6vw, 1rem)",
                        color: "#7d0df1",
                      }}
                    />
                    Interactive
                  </TextGradient>{" "}
                  services.
                </TopText>
              </TopTextContainer>
              <ButtonContainer
                style={{ justifyContent: "flex-start", paddingLeft: "3vw" }}
              >
                <NavLink to="/services">
                  <Button>OUR SERVICES</Button>
                </NavLink>
              </ButtonContainer>
            </TopRightContainer>
          </TopContainer>
          <BottomContainer>
            <BottomLeftContainer>
              <BottomTextContainer>
                <BottomText>
                  Connects projects with the right professionals. <br />{" "}
                  Provides{" "}
                  <TextGradient color="#f94819" secondColor="#ffc8b9">
                    Equipment
                  </TextGradient>{" "}
                  and{" "}
                  <TextGradient color="#f94819" secondColor="#ffc8b9">
                    Studio Spaces
                  </TextGradient>{" "}
                </BottomText>
              </BottomTextContainer>
              <ButtonContainer
                style={{ justifyContent: "flex-end", paddingRight: "5vw" }}
              >
                <NavLink to="/equipments">
                  <Button>EQUIPMENTS</Button>
                </NavLink>
              </ButtonContainer>
            </BottomLeftContainer>
            <BottomRightContainer>
              <ShapeShift />
            </BottomRightContainer>
          </BottomContainer>
        </Wrapper>
      </Container>
    </>
  );
};

export default HomeScreen2;
