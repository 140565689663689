import React from "react";
import { Helmet } from "react-helmet-async";
import styled from "styled-components";
import HomeScreen from "../components/HomeScreen/HomeScreen";
import HomeScreen2 from "../components/HomeScreen/HomeScreen2";
import HomeScreen3 from "../components/HomeScreen/HomeScreen3";

const Container = styled.div``;

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Home / Stüdyo Miskin</title>
      </Helmet>
      <Container>
        <HomeScreen />
        <HomeScreen2 />
        <HomeScreen3 />
      </Container>
    </>
  );
};

export default Home;
