import React from "react";
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  NavLink,
} from "./SidebarElements";

const Sidebar = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer
      isOpen={isOpen}
      onClick={toggle}
      className="SidebarContainer"
    >
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <NavLink to="/services" onClick={toggle}>
            SERVICES
          </NavLink>
          <NavLink to="/facilities" onClick={toggle}>
            FACILITIES
          </NavLink>
          <NavLink to="/portfolio" onClick={toggle}>
            PORTFOLIO
          </NavLink>
          <NavLink to="/clients" onClick={toggle}>
            CLIENTS
          </NavLink>
          <NavLink to="/team" onClick={toggle}>
            TEAM
          </NavLink>
          <NavLink to="/contact" onClick={toggle}>
            CONTACT
          </NavLink>
        </SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
