import React from "react";
import styled from "styled-components";
import About3Img from "../../images/Studyo-Miskin-Home-32.jpg";
import gsap from "gsap";
import { useEffect } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const Container = styled.div`
  height: 100vh;
  padding: 10% 6% 5% 6%;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const TextContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  width: 55%;
  height: 100%;
  padding-left: 2%;
  padding-top: 14%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  gap: 2vh;
`;

const Text = styled.p`
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.2vw;
  color: #efefef;
  line-height: 1.8;
`;

const ImageContainer = styled.div`
  position: absolute;
  right: 0;
  width: 55%;
  height: 100%;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-mask-image: -webkit-gradient(
    linear,
    left center,
    right center,
    from(rgba(255, 255, 255, 0)),
    to(rgba(255, 255, 255, 1))
  );
  mask-image: -webkit-gradient(
    linear,
    left center,
    right center,
    from(rgba(255, 255, 255, 0)),
    to(rgba(255, 255, 255, 1))
  );
`;

const TextGradientContainer = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  height: 4.5vw;
  transform: translateY(-20%);
`;

const TextGradient = styled.h1`
  background-image: linear-gradient(
    to right,
    ${(props) => props.color} 0%,
    ${(props) => props.secondColor} 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: DINProMedium;
  letter-spacing: 0.02em;
  font-size: 5vw;
  transform: translateY(100%);
  margin: 0;
`;

const AbouttScreen3 = () => {
  useEffect(() => {
    const tl = gsap.timeline({});
    ScrollTrigger.create({
      trigger: ".gradient-container",
      toggleActions: "restart pause restart none",
      animation: tl,
    });

    tl.to(".gradient-text", {
      y: 0,
      duration: 0.6,
    });

    return () => {
      ScrollTrigger.killAll();
    };
  }, []);
  return (
    <Container className="panel">
      <Wrapper>
        <TextContainer>
          <TextGradientContainer className="gradient-container">
            <TextGradient
              color="#f94819"
              secondColor="#ffc8b9"
              className="gradient-text"
            >
              OUR HISTORY
            </TextGradient>
          </TextGradientContainer>
          <Text>
            There are a variety of instruments, microphones, control surfaces
            and other audio equipment options available for use, which are
            suited for many different genres.
          </Text>
        </TextContainer>
        <ImageContainer>
          <Image src={About3Img} alt="" />
        </ImageContainer>
      </Wrapper>
    </Container>
  );
};

export default AbouttScreen3;
