import React from "react";
import AliceCarousel from "react-alice-carousel";
import "./alice-carousel.css";
import styled from "styled-components";
import img1 from "../../images/references/1.png";
import img2 from "../../images/references/2.png";
import img3 from "../../images/references/3.png";
import img4 from "../../images/references/4.png";
import img5 from "../../images/references/5.png";
import img6 from "../../images/references/6.png";
import img7 from "../../images/references/7.png";
import img8 from "../../images/references/8.png";
import img9 from "../../images/references/9.png";
import img10 from "../../images/references/10.png";
import img11 from "../../images/references/11.jpg";
import img12 from "../../images/references/12.png";
import img13 from "../../images/references/13.png";
import img14 from "../../images/references/14.png";
import img15 from "../../images/references/15.png";
import img16 from "../../images/references/16.png";
import img17 from "../../images/references/17.png";
import img18 from "../../images/references/18.png";
import img19 from "../../images/references/19.png";
import img20 from "../../images/references/20.png";
import img21 from "../../images/references/21.png";
import img22 from "../../images/references/22.png";
import img23 from "../../images/references/23.png";
import img24 from "../../images/references/24.png";
import img25 from "../../images/references/25.jpg";

const Container = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
`;

const CarouselContainer = styled.div`
  width: 100%;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 15%;
`;

const responsive = {
  0: { items: 3 },
  767: { items: 5 },
  1279: { items: 7 },
};

const items = [
  <div className="item" data-value="1">
    <Image src={img1} />
  </div>,
  <div className="item" data-value="2">
    <Image src={img2} />
  </div>,
  <div className="item" data-value="3">
    <Image src={img3} />
  </div>,
  <div className="item" data-value="4">
    <Image src={img4} />
  </div>,
  <div className="item" data-value="5">
    <Image src={img5} />
  </div>,
  <div className="item" data-value="6">
    <Image src={img6} />
  </div>,
  <div className="item" data-value="7">
    <Image src={img7} />
  </div>,
  <div className="item" data-value="8">
    <Image src={img8} />
  </div>,
  <div className="item" data-value="9">
    <Image src={img9} />
  </div>,
  <div className="item" data-value="10">
    <Image src={img10} />
  </div>,
  <div className="item" data-value="11">
    <Image src={img11} />
  </div>,
  <div className="item" data-value="12">
    <Image src={img12} />
  </div>,
  <div className="item" data-value="13">
    <Image src={img13} />
  </div>,
  <div className="item" data-value="14">
    <Image src={img14} />
  </div>,
  <div className="item" data-value="15">
    <Image src={img15} />
  </div>,
  <div className="item" data-value="16">
    <Image src={img16} />
  </div>,
  <div className="item" data-value="17">
    <Image src={img17} />
  </div>,
  <div className="item" data-value="18">
    <Image src={img18} />
  </div>,
  <div className="item" data-value="19">
    <Image src={img19} />
  </div>,
  <div className="item" data-value="20">
    <Image src={img20} />
  </div>,
  <div className="item" data-value="21">
    <Image src={img21} />
  </div>,
  <div className="item" data-value="22">
    <Image src={img22} />
  </div>,
  <div className="item" data-value="23">
    <Image src={img23} />
  </div>,
  <div className="item" data-value="24">
    <Image src={img24} />
  </div>,
  <div className="item" data-value="25">
    <Image src={img25} />
  </div>,
];

const Carousel = () => (
  <>
    <Container>
      <CarouselContainer>
        <AliceCarousel
          autoPlay
          autoPlayStrategy="default"
          autoPlayInterval={0}
          autoPlayDirection="ltr"
          animationDuration={5000}
          animationType="slide"
          animationEasingFunction="linear"
          infinite={true}
          touchTracking={true}
          disableDotsControls
          disableButtonsControls
          items={items}
          responsive={responsive}
          controlsStrategy="default"
        />
      </CarouselContainer>
    </Container>
  </>
);

export default Carousel;
