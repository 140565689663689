import React from 'react';
import styled from 'styled-components';
import BackgroundImage from '../images/Background/noise-transparent.png';

const GradientBackground = styled.div`
  margin: 0;
  overflow-x: hidden;
  background: radial-gradient(
    circle,
    rgba(40, 40, 40, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  background-size: 200% 200%;
  animation: gradient 60s ease infinite;
  z-index: -2;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  @keyframes gradient {
    0% {
      background-position: 0% 0%;
    }
    25% {
      background-position: 100% 0%;
    }
    50% {
      background-position: 100% 100%;
    }
    75% {
      background-position: 0% 100%;
    }
    100% {
      background-position: 0% 0%;
    }
  }
`;

const BackgroundNoise = styled.div`
  position: fixed;
  top: -50%;
  left: -50%;
  right: -50%;
  bottom: -50%;
  z-index: -1;
  width: 200%;
  height: 200vh;
  background: transparent url(${BackgroundImage}) repeat 0 0;
  background-repeat: repeat;
  animation: bg-animation 0.2s infinite;
  opacity: 1;
  visibility: visible;

  @keyframes bg-animation {
    0% {
      transform: translate(0, 0);
    }
    10% {
      transform: translate(-5%, -5%);
    }
    20% {
      transform: translate(-10%, 5%);
    }
    30% {
      transform: translate(5%, -10%);
    }
    40% {
      transform: translate(-5%, 15%);
    }
    50% {
      transform: translate(-10%, 5%);
    }
    60% {
      transform: translate(15%, 0);
    }
    70% {
      transform: translate(0, 10%);
    }
    80% {
      transform: translate(-15%, 0);
    }
    90% {
      transform: translate(10%, 5%);
    }
    100% {
      transform: translate(5%, 0);
    }
  }
`;

const Noise = () => {
  return (
    <>
      <GradientBackground>
        <BackgroundNoise></BackgroundNoise>
      </GradientBackground>
    </>
  );
};

export default Noise;
