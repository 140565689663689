import { useMemo } from "react";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  aspect-ratio: 3/2;
  filter: grayscale(100);
  overflow: hidden;

  @media screen and (max-width: 460px) {
    aspect-ratio: 1/1;
  }
`;

export default function MiskinMap() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBxLX3WUls_J5T1g1lWGRORbl7RAdRDYok",
  });

  if (!isLoaded) return <div>Loading...</div>;
  return <Map />;
}

function Map() {
  const center = useMemo(
    () => ({ lat: 52.33529121013531, lng: 4.934762239103047 }),
    []
  );

  return (
    <Container>
      <GoogleMap
        zoom={17}
        center={center}
        mapContainerClassName="map-container"
        options={{
          disableDefaultUI: true,
        }}
      >
        <MarkerF position={center} />
      </GoogleMap>
    </Container>
  );
}
