import styled from "styled-components";
import { NavLink as Link } from "react-router-dom";

export const FooterContainer = styled.footer`
  width: 100%;
`;

export const FooterWrap = styled.div`
  height: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 6%;

  @media screen and (max-width: 1024px) {
    /* margin: 0 8%; */
    flex-direction: column;
    justify-content: center;
    height: 150px;
    gap: 1.8rem;
  }

  @media screen and (max-width: 768px) {
    height: 200px;
  }
`;

export const SocialIcons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
`;

export const SocialIconLink = styled.a`
  display: flex;
  color: #707070;
  font-size: 1.8rem;

  &:hover {
    color: #f94819;
    transition: 0.2s ease-out;
  }

  @media screen and (max-width: 1024px) {
    font-size: 2.2rem;
  }
`;

export const FooterLinksWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;

  @media screen and (max-width: 1024px) {
    justify-content: space-between;
    gap: 0rem;
    width: 100%;
  }
`;

export const NavLink = styled(Link)`
  color: #707070;
  font-family: DINProMedium;
  letter-spacing: 0.02em;
  font-size: 1.2rem;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &.active {
    color: #f94819;
  }
  &:hover {
    color: #f94819;
  }
`;

export const WebsiteRights = styled.p`
  color: #707070;
  font-family: DINProMedium;
  letter-spacing: 0.02em;
  font-size: 1.2rem;
  margin: 0;
  user-select: none;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const Span = styled.span`
  display: flex;
  font-family: DINProMedium;
  font-size: 1.2rem;
  letter-spacing: 0.02em;

  @media screen and (max-width: 480px) {
    display: none;
  }
`;

export const MobileWrapper = styled.div`
  display: none;

  @media screen and (max-width: 480px) {
    display: flex;
    width: 100%;
    font-size: 1.2rem;
    justify-content: space-between;
    align-items: center;
  }

  @media all and (min-width: 481px) and (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const WebsiteRightsMobile = styled.p`
  color: #707070;
  font-family: DINProMedium;
  letter-spacing: 0.02em;
  font-size: 1.2rem;
  margin: 0;
  user-select: none;
`;
