import styled from "styled-components";
import { NavLink as Link } from "react-router-dom";

export const Nav = styled.nav`
  width: 100%;
  height: 100px;
  display: flex;
  z-index: 999;
  -webkit-backdrop-filter: ${({ scrollNav }) =>
    scrollNav ? "blur(10px)" : "blur(0px)"};
  backdrop-filter: ${({ scrollNav }) =>
    scrollNav ? "blur(10px)" : "blur(0px)"};
  transition: all 0.2s ease-in-out;

  @media screen and (max-width: 480px) {
    height: 60px;
  }
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${({ scrollNav }) => (scrollNav ? "80px" : "100px")};
  z-index: 1;
  width: 100%;
  padding: 0 6%;
  transition: all 0.2s ease-in-out;

  @media screen and (max-width: 480px) {
    height: 60px;
  }
`;

export const NavLogo = styled(Link)`
  width: 106px;
  cursor: pointer;
  display: flex;
  align-items: center;

  @media screen and (max-width: 480px) {
    width: 70px;
  }
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: flex;
    height: fit-content;
    font-size: 1.8rem;
    cursor: pointer;
    color: #efefef;
    transition: 0.3s ease-in-out;
    transform: ${({ isOpen }) => (isOpen ? "rotate(-90deg)" : "rotate(0deg)")};
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  justify-content: flex-end;
  gap: 5vw;
  align-items: center;
  list-style: none;
  margin: 0;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavItem = styled.li`
  display: flex;
  flex-direction: row;
`;

export const NavLink = styled(Link)`
  font-family: DINProMedium;
  letter-spacing: 0.02em;
  color: #efefef;
  text-decoration: none;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.1rem;

  &.active {
    color: #f94819;
    /* background: #f94819;
    padding: 2px 6px;
    margin: -2px -6px;
    transition: background 0.2s ease-in-out; */
  }

  &:hover {
    color: #f94819;
    transition: 0.2s ease-in-out;
  }
`;
