import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import styled from "styled-components";
import Map from "../components/Map";

const Container = styled.div`
  padding: 50px 6% 100px 6%;
  display: flex;
  flex-direction: column;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InfoBoxContainer = styled.div`
  width: 100%;
  height: 30vh;
  padding-top: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5vh;
`;

const Title = styled.h1`
  color: #efefef;
  font-size: 2vw;
  text-transform: uppercase;
`;

const Anchor = styled.a`
  color: #707070;
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 100;
  font-size: 1vw;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    color: #efefef;
  }
`;

const BottomWrapper = styled.div`
  height: 100%;
  display: flex;
  gap: 5%;
  overflow: hidden;
`;

const MapWrapper = styled.div`
  height: 100%;
  padding: 0 5%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2.5vh;
  flex: 1;
`;

const FormWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 5%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  gap: 4vh;
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3vh;
`;

const Input = styled.input`
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 100;
  font-size: 1vw;
  color: #efefef;
  background-color: transparent;
  padding: 1% 2%;
  border-bottom: 1px solid #707070;
  border-top: none;
  border-left: none;
  border-right: none;
  outline: none;
  border-radius: 0;
`;

const TextArea = styled.textarea`
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 100;
  font-size: 1vw;
  color: #efefef;
  background-color: transparent;
  padding: 1% 2%;
  border-bottom: 1px solid #707070;
  border-top: none;
  border-left: none;
  border-right: none;
  outline: none;
  border-radius: 0;
`;

const Button = styled.button`
  font-family: DINProMedium;
  letter-spacing: 0.04em;
  font-size: 1vw;
  color: #efefef;
  background-color: transparent;
  padding: 0.9vw 1.8vw;
  border-radius: 100px;
  border: 1px solid #efefef;
  width: fit-content;
  align-self: flex-end;

  &:hover {
    animation: glow 0.2s linear both;
  }

  @keyframes glow {
    to {
      box-shadow: 0 0 50px -25px #efefef;
      color: #0d0f0f;
      background: #efefef;
    }
  }
`;

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    question: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, email, question } = formData;
    const mailtoLink = `mailto:info@studyomiskin.com?body=Name: ${name}%0D%0AQuestion: ${question}%0D%0AReply to: ${email}`;

    window.location.href = mailtoLink;
  };
  return (
    <>
      <Helmet>
        <title>Contact / Stüdyo Miskin</title>
      </Helmet>
      <Container>
        <TopWrapper>
          <InfoBoxContainer>
            <Title>write us</Title>
            <Anchor href="mailto:info@studyomiskin.com">
              info@studyomiskin.com
            </Anchor>
          </InfoBoxContainer>
          <InfoBoxContainer>
            <Title>call us</Title>
            <Anchor href="tel:+905306129642">+90 530 612 96 42</Anchor>
            <Anchor href="tel:+905363426109">+90 536 342 61 09</Anchor>
            <Anchor href="tel:+34677649244">+34 677 64 92 44</Anchor>
          </InfoBoxContainer>
          <InfoBoxContainer>
            <Title>visit us</Title>
            <Anchor>
              Joop Geesinkweg 901-999, <br /> 1ste, 2de & 3de Verdieping,
              <br /> 1114 AB Amsterdam,
              <br /> Netherlands
            </Anchor>
          </InfoBoxContainer>
          <InfoBoxContainer>
            <Title>follow us</Title>
            <Anchor href="//www.instagram.com/studyomiskin/" target="_blank">
              Instagram
            </Anchor>
            <Anchor href="//www.vimeo.com/studyomiskin" target="_blank">
              Vimeo
            </Anchor>
          </InfoBoxContainer>
        </TopWrapper>
        <BottomWrapper>
          <MapWrapper>
            <Title>our location</Title>
            <Map />
          </MapWrapper>
          <FormWrapper>
            <Title>have a question?</Title>
            <Form onSubmit={handleSubmit}>
              <Input
                type="text"
                placeholder="Name*"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
              />
              <Input
                type="email"
                placeholder="E-Mail*"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
              <TextArea
                placeholder="Your Question*"
                name="question"
                value={formData.question}
                onChange={handleInputChange}
              />
              <Button type="submit">SUBMIT</Button>
            </Form>
          </FormWrapper>
        </BottomWrapper>
      </Container>
    </>
  );
};

export default Contact;
