import React from 'react';
import styled from 'styled-components';
import EgeVeKivancImg from '../../images/egevekivanc.png';

const Container = styled.div`
  height: 100vh;
  top: 0;
  display: flex;
  position: relative;
`;

const ImgContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  width: 80%;
  height: 100%;
  object-fit: cover;
`;

const AboutScreen2 = () => {
  return (
    <Container className="panel">
      <ImgContainer>
        <Image src={EgeVeKivancImg} alt="" />
      </ImgContainer>
    </Container>
  );
};

export default AboutScreen2;
