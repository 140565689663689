import React, { useState } from "react";
import styled from "styled-components";

const SlideshowContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Slideshow = ({
  auditoryComponents,
  visualComponents,
  interactiveComponents,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  return (
    <SlideshowContainer>
      {visualComponents && (
        <>
          {visualComponents.map((Component, index) => (
            <div
              key={index}
              style={{ display: index === currentIndex ? "block" : "none" }}
            >
              <Component />
            </div>
          ))}
          <div
            style={{
              display: "flex",
              gap: "20px",
              alignSelf: "center",
              marginBottom: "50px",
              marginTop: "-75px",
            }}
          >
            {visualComponents.map((_, index) => (
              <span
                key={index}
                onClick={() => goToSlide(index)}
                style={{
                  cursor: "pointer",
                  width: "12px",
                  height: "12px",
                  borderRadius: "50%",
                  border: "1px solid #404040",
                  backgroundColor:
                    index === currentIndex ? "#404040" : "transparent",
                }}
              />
            ))}
          </div>
        </>
      )}
      {auditoryComponents && (
        <>
          {auditoryComponents.map((Component, index) => (
            <div
              key={index}
              style={{ display: index === currentIndex ? "block" : "none" }}
            >
              <Component />
            </div>
          ))}
          <div
            style={{
              display: "flex",
              gap: "20px",
              alignSelf: "center",
              marginBottom: "50px",
              marginTop: "-75px",
            }}
          >
            {auditoryComponents.map((_, index) => (
              <span
                key={index}
                onClick={() => goToSlide(index)}
                style={{
                  cursor: "pointer",
                  width: "12px",
                  height: "12px",
                  borderRadius: "50%",
                  border: "1px solid #404040",
                  backgroundColor:
                    index === currentIndex ? "#404040" : "transparent",
                }}
              />
            ))}
          </div>
        </>
      )}
      {interactiveComponents && (
        <>
          {interactiveComponents.map((Component, index) => (
            <div
              key={index}
              style={{ display: index === currentIndex ? "block" : "none" }}
            >
              <Component />
            </div>
          ))}
          <div
            style={{
              display: "flex",
              gap: "20px",
              alignSelf: "center",
              marginBottom: "50px",
              marginTop: "-75px",
            }}
          >
            {interactiveComponents.map((_, index) => (
              <span
                key={index}
                onClick={() => goToSlide(index)}
                style={{
                  cursor: "pointer",
                  width: "12px",
                  height: "12px",
                  borderRadius: "50%",
                  border: "1px solid #404040",
                  backgroundColor:
                    index === currentIndex ? "#404040" : "transparent",
                }}
              />
            ))}
          </div>
        </>
      )}
    </SlideshowContainer>
  );
};

export default Slideshow;
