import React from "react";
import { FaInstagram, FaVimeoV } from "react-icons/fa";
import {
  FooterContainer,
  FooterWrap,
  SocialIcons,
  SocialIconLink,
  FooterLinksWrapper,
  NavLink,
  WebsiteRights,
  MobileWrapper,
  WebsiteRightsMobile,
  Span,
} from "./FooterElements";

const Footer = () => {
  return (
    <>
      <FooterContainer>
        <FooterWrap>
          <SocialIcons>
            <SocialIconLink
              href="//www.instagram.com/studyomiskin/"
              target="_blank"
            >
              <FaInstagram />
            </SocialIconLink>
            <SocialIconLink href="//www.vimeo.com/studyomiskin" target="_blank">
              <FaVimeoV />
            </SocialIconLink>
          </SocialIcons>
          <FooterLinksWrapper>
            <NavLink to="/faq">FAQ</NavLink>
            <NavLink to="/privacypolicies">PRIVACY POLICIES</NavLink>
            <NavLink to="/termsofuse">TERMS OF USE</NavLink>
            <NavLink to="/kvkk">KVKK</NavLink>
            <Span>
              <NavLink to="/brand">BRAND</NavLink>
            </Span>
            <WebsiteRights>ALL RIGHTS RESERVED © STUDYO MISKIN</WebsiteRights>
          </FooterLinksWrapper>
          <MobileWrapper>
            <NavLink to="/brand" id="brand1024">
              BRAND
            </NavLink>
            <WebsiteRightsMobile>
              ALL RIGHTS RESERVED © STUDYO MISKIN
            </WebsiteRightsMobile>
          </MobileWrapper>
        </FooterWrap>
      </FooterContainer>
    </>
  );
};

export default Footer;
