import React from 'react';
import {
  Container,
  Wrapper,
  PurpleCircle,
  BlueCircle,
  GreenCircle,
} from './CirclesElements';

const Circles = () => {
  return (
    <Container>
      <Wrapper>
        <BlueCircle></BlueCircle>
        <GreenCircle></GreenCircle>
        <PurpleCircle></PurpleCircle>
      </Wrapper>
    </Container>
  );
};

export default Circles;
