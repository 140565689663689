import React from "react";
import { Helmet } from "react-helmet-async";
import styled from "styled-components";
import AboutScreen from "../components/AboutScreen/AboutScreen";
import AboutScreen2 from "../components/AboutScreen/AboutScreen2";
import AboutScreen3 from "../components/AboutScreen/AboutScreen3";
import AboutScreen4 from "../components/AboutScreen/AboutScreen4";
import { useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const Container = styled.div``;

const About = () => {
  useEffect(() => {
    gsap.to(".pin-screen", {
      scrollTrigger: {
        trigger: ".pin-screen",
        start: "center center",
        end: "+=100%",
        scrub: true,
        pin: true,
      },
      transform: "scale(0.9)",
      filter: "blur(10px)",
      ease: "none",
    });

    return () => {
      ScrollTrigger.killAll();
    };
  }, []);
  return (
    <>
      <Helmet>
        <title>About / Stüdyo Miskin</title>
      </Helmet>
      <Container>
        <AboutScreen />
        <AboutScreen2 />
        <AboutScreen3 />
        <AboutScreen4 />
      </Container>
    </>
  );
};

export default About;
