import React from "react";
import styled from "styled-components";
import Carousel from "../Carousel/index";

const Container = styled.section`
  width: 100%;
  height: 80vh;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HomeScreen3 = () => {
  return (
    <>
      <Container className="panel">
        <Carousel />
      </Container>
    </>
  );
};

export default HomeScreen3;
