import React from "react";
import styled from "styled-components";
import Img1 from "../../images/visual-2.jpg";
import Img2 from "../../images/visual-3.jpg";
import Img3 from "../../images/visual-4.jpg";

const Container = styled.div`
  min-height: 80vh;
  padding: 100px 0;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TopContainer = styled.div`
  display: flex;
`;

const ImageContainer = styled.div`
  display: flex;
  width: 100%;
  aspect-ratio: 3/2;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  filter: grayscale(100);
  object-fit: cover;
  /* -webkit-mask-image: -webkit-gradient(
    linear,
    left center,
    right center,
    from(rgba(255, 255, 255, 0)),
    to(rgba(255, 255, 255, 1))
  ); */
  /* mask-image: linear-gradient(
    to top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  ); */
  mask-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(
      to top,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 0) 100%
    );
`;

const TextContainer = styled.div`
  flex: 0.7;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 5vh;
  min-height: 300px;
`;

const LeftContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 90%;
`;

const Title = styled.h1`
  text-transform: uppercase;
  color: #efefef;
  font-size: 2.6vw;
  font-family: DINProMedium;
  letter-spacing: 0.02em;
`;

const Paragraph = styled.p`
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 100;
  color: #efefef;
  font-size: 1.1vw;
`;

const CentreContainer = styled.div`
  width: 100%;
`;

const RightContainer = styled.div`
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 100;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 6% 0 8%;
`;

const ListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.8vh;
`;

const ListItem = styled.p`
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 100;
  color: #efefef;
  font-size: 1.1vw;
  margin: 0;
`;

const VisualPreProduction = () => {
  return (
    <Container className="panel">
      <Wrapper>
        <TopContainer>
          <ImageContainer>
            <Image src={Img1} alt="" />
          </ImageContainer>
          <ImageContainer>
            <Image src={Img2} alt="" />
          </ImageContainer>
          <ImageContainer>
            <Image src={Img3} alt="" />
          </ImageContainer>
        </TopContainer>
        <TextContainer>
          <LeftContainer>
            <Title>pre production</Title>
          </LeftContainer>
          <CentreContainer>
            <Paragraph>
              Step into the world of visual pre-production, where creativity and
              expertise collide to shape your vision. Our directing services are
              your compass, guiding each frame with precision. Our
              cinematographers breathe life into your narrative, capturing the
              essence of your story. Art direction is our canvas, painting your
              concepts into reality. Elevate your brand's identity with our
              branding expertise, and let us weave your digital dreams with web
              and graphic design. Let's embark on a journey where your visuals
              become a masterpiece.
            </Paragraph>
          </CentreContainer>
          <RightContainer>
            <ListContainer>
              <ListItem>Directing Services</ListItem>
              <ListItem>Cinematographer Services</ListItem>
              <ListItem>Art Directing</ListItem>
              <ListItem>Branding</ListItem>
              <ListItem>Web Design</ListItem>
            </ListContainer>
            <ListContainer>
              <ListItem>Graphic Design</ListItem>
            </ListContainer>
          </RightContainer>
        </TextContainer>
      </Wrapper>
    </Container>
  );
};

export default VisualPreProduction;
