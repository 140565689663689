import React from "react";
import styled from "styled-components";
import Kivanc from "../../images/kivanc.jpg";
import Ege from "../../images/ege.jpg";
import Baris from "../../images/baris.jpg";
import Berk from "../../images/berk.jpg";

const Container = styled.div`
  height: 100vh;
  width: 100%;
  top: 0;
  padding: 0 6%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2vh;
`;

const TitleContainer = styled.div``;

const Title = styled.h1`
  font-size: 9vw;
  font-family: DINProMedium;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #efefef;
  user-select: none;
`;

const TeamContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const TeamMemberContainer = styled.div`
  width: 20%;
  cursor: default;

  &:hover {
    .info-container {
      /* visibility: visible; */
      background-color: rgba(13, 15, 15, 0.8);
      opacity: 1;
    }
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  aspect-ratio: 3/4;
  display: flex;
  position: relative;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  mix-blend-mode: screen;
`;

const InfoContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
  padding: 20% 10%;
`;

const InfoText = styled.p`
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 100;
  font-size: 0.9vw;
  color: #707070;
  text-align: left;
`;

const InfoSocials = styled.div``;

const TextContainer = styled.div`
  padding: 5% 1%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  line-height: 1;
`;

const NameText = styled.h1`
  font-size: 1.8vw;
  color: #efefef;
  margin: 0;
`;

const TitleText = styled.p`
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 100;
  font-size: 1.1vw;
  color: #707070;
  margin: 0;
`;

const AboutScreen4 = () => {
  const infoText =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.";
  return (
    <Container className="panel">
      <Wrapper>
        <TitleContainer>
          <Title>meet our team</Title>
        </TitleContainer>
        <TeamContainer>
          <TeamMemberContainer>
            <ImageContainer>
              <Image src={Kivanc} />
              <InfoContainer className="info-container">
                <InfoText>{infoText}</InfoText>
                <InfoSocials></InfoSocials>
              </InfoContainer>
            </ImageContainer>
            <TextContainer>
              <NameText>KIVANÇ KORKMAZ</NameText>
              <TitleText>Producer / Cinematographer</TitleText>
            </TextContainer>
          </TeamMemberContainer>
          <TeamMemberContainer>
            <ImageContainer>
              <Image src={Ege} />
              <InfoContainer className="info-container">
                <InfoText>{infoText}</InfoText>
                <InfoSocials></InfoSocials>
              </InfoContainer>
            </ImageContainer>
            <TextContainer>
              <NameText>EGE GEVELİ</NameText>
              <TitleText>Producer</TitleText>
            </TextContainer>
          </TeamMemberContainer>
          <TeamMemberContainer>
            <ImageContainer>
              <Image src={Baris} />
              <InfoContainer className="info-container">
                <InfoText>{infoText}</InfoText>
                <InfoSocials></InfoSocials>
              </InfoContainer>
            </ImageContainer>
            <TextContainer>
              <NameText>BARIŞ KİŞİOĞLU</NameText>
              <TitleText>Manager / Producer</TitleText>
            </TextContainer>
          </TeamMemberContainer>
          <TeamMemberContainer>
            <ImageContainer>
              <Image src={Berk} />
              <InfoContainer className="info-container">
                <InfoText>{infoText}</InfoText>
                <InfoSocials></InfoSocials>
              </InfoContainer>
            </ImageContainer>
            <TextContainer>
              <NameText>BERK ÖZALP</NameText>
              <TitleText>Content Creator</TitleText>
            </TextContainer>
          </TeamMemberContainer>
        </TeamContainer>
      </Wrapper>
    </Container>
  );
};

export default AboutScreen4;
