import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import styled from "styled-components";
import Auditory from "../components/ServicesScreen/Auditory";
import AuditoryPreProduction from "../components/ServicesScreen/AuditoryOther";
import AuditoryProduction from "../components/ServicesScreen/AuditoryProduction";
import AuditoryPostProduction from "../components/ServicesScreen/AuditoryPostProduction";
import Visual from "../components/ServicesScreen/Visual";
import VisualPreProduction from "../components/ServicesScreen/VisualPreProduction";
import VisualProduction from "../components/ServicesScreen/VisualProduction";
import VisualPostProduction from "../components/ServicesScreen/VisualPostProduction";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect } from "react";
import Slideshow from "../components/ServicesScreen/Slideshow";
import Interactive from "../components/ServicesScreen/Interactive";
import InteractiveTechnical from "../components/ServicesScreen/InteractiveTechnical";
import InteractiveEvent from "../components/ServicesScreen/InteractiveEvent";

gsap.registerPlugin(ScrollTrigger);

const Container = styled.div``;

const CircleContainer = styled.div`
  height: calc(100vh - 100px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 5%;
`;

export const CircleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4%;
  padding: 0 18%;
  margin: auto;
  width: 100%;
  height: 100%;
  position: relative;
`;

export const PurpleCircle = styled.div`
  border-radius: 50%;
  aspect-ratio: 1 / 1;
  width: 100%;
  display: flex;
  position: relative;
  background: transparent;
  border: none;
  mix-blend-mode: screen;
  box-shadow: 0 0 50px -20px #7d0df1, inset 0 0 50px -20px #7d0df1;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    padding: 2px;
    border-radius: 50%;
    transform: rotate(0);
    filter: blur(1px);
    animation: rot 20s linear;
    background: linear-gradient(0deg, #7d0df1, #efefef),
      linear-gradient(0deg, #ffffff, #ffffff);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  &:hover {
    animation: shine 1s linear forwards;
  }

  @keyframes shine {
    50% {
      box-shadow: 0 0 50px -15px #7d0df1, inset 0 0 50px -15px #7d0df1;
    }
    100% {
      box-shadow: 0 0 100px -30px #7d0df1, inset 0 0 100px -50px #7d0df1;
    }
  }

  @keyframes rot {
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const BlueCircle = styled.div`
  box-shadow: 0 0 50px -20px #0dcef1, inset 0 0 50px -20px #0dcef1;
  border-radius: 50%;
  aspect-ratio: 1 / 1;
  width: 100%;
  display: flex;
  position: relative;
  background: transparent;
  border: none;
  mix-blend-mode: screen;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    padding: 2px;
    border-radius: 50%;
    transform: rotate(0);
    animation: rot 20s linear;
    filter: blur(1px);
    background: linear-gradient(225deg, #0dcef1, #efefef),
      linear-gradient(0deg, #ffffff, #ffffff);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  &:hover {
    animation: shineB 1s linear forwards;
  }

  @keyframes shineB {
    50% {
      box-shadow: 0 0 50px -15px #0dcef1, inset 0 0 50px -15px #0dcef1;
    }
    100% {
      box-shadow: 0 0 100px -30px #0dcef1, inset 0 0 100px -50px #0dcef1;
    }
  }
`;

export const GreenCircle = styled.div`
  box-shadow: 0 0 50px -20px #0deb69, inset 0 0 50px -20px #0deb69;
  border-radius: 50%;
  aspect-ratio: 1 / 1;
  width: 100%;
  display: flex;
  position: relative;
  background: transparent;
  border: none;
  mix-blend-mode: screen;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    padding: 2px;
    border-radius: 50%;
    transform: rotate(0);
    filter: blur(1px);
    animation: rot 20s linear;
    background: linear-gradient(125deg, #0deb69, #efefef),
      linear-gradient(0deg, #ffffff, #ffffff);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  &:hover {
    animation: shineG 1s linear forwards;
  }

  @keyframes shineG {
    50% {
      box-shadow: 0 0 50px -15px #0deb69, inset 0 0 50px -15px #0deb69;
    }
    100% {
      box-shadow: 0 0 100px -30px #0deb69, inset 0 0 100px -50px #0deb69;
    }
  }
`;

const Button = styled.div`
  font-family: DINProMedium;
  letter-spacing: 0.02em;
  font-size: max(2vw, 1.2rem);
  cursor: pointer;
  text-decoration: none;
  border: none;
  z-index: 9;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(
    to right,
    ${(props) => props.color} 0%,
    ${(props) => props.secondColor} 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.5s linear;
  opacity: 0;
  filter: blur(1px);

  &:hover {
    opacity: 1;
    filter: blur(0px);
    transition: all 0.8s linear;
  }
`;

const VisualWrapper = styled.div``;
const AuditoryWrapper = styled.div``;
const InteractiveWrapper = styled.div``;

const Services = () => {
  const auditoryComponents = [
    AuditoryProduction,
    AuditoryPostProduction,
    AuditoryPreProduction,
  ];
  const visualComponents = [
    VisualPreProduction,
    VisualProduction,
    VisualPostProduction,
  ];
  const interactiveComponents = [InteractiveTechnical, InteractiveEvent];

  const [activeComponent, setActiveComponent] = useState("");
  const visualWrapperRef = useRef(null);
  const auditoryWrapperRef = useRef(null);
  const interactiveWrapperRef = useRef(null);

  const handleButtonClick = (component) => {
    setActiveComponent(component);
    if (component === "Visual" && visualWrapperRef.current) {
      visualWrapperRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (component === "Auditory" && auditoryWrapperRef.current) {
      auditoryWrapperRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (component === "Interactive" && interactiveWrapperRef.current) {
      interactiveWrapperRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleButtonHover = (component) => {
    setActiveComponent(component);
  };

  useEffect(() => {
    gsap.to(".bottom-container", {
      scrollTrigger: {
        trigger: ".bottom-container",
        start: 0,
        end: "+=100%",
        scrub: true,
        pin: true,
      },
      y: "0%",
      ease: "power1",
    });

    return () => {
      ScrollTrigger.killAll();
    };
  }, []);
  return (
    <>
      <Helmet>
        <title>Services / Stüdyo Miskin</title>
      </Helmet>
      <Container>
        <CircleContainer>
          <CircleWrapper>
            <BlueCircle>
              {" "}
              <Button
                onMouseEnter={() => handleButtonHover("Auditory")}
                onClick={() => handleButtonClick("Auditory")}
                color="#0dcef1"
                secondColor="#97dce8"
              >
                AUDITORY
              </Button>
            </BlueCircle>
            <GreenCircle>
              <Button
                onMouseEnter={() => handleButtonHover("Visual")}
                onClick={() => handleButtonClick("Visual")}
                color="#0deb69"
                secondColor="#9bd7b4"
              >
                VISUAL
              </Button>
            </GreenCircle>
            <PurpleCircle>
              <Button
                onMouseEnter={() => handleButtonHover("Interactive")}
                onClick={() => handleButtonClick("Interactive")}
                color="#7d0df1"
                secondColor="#bb8deb"
              >
                INTERACTIVE
              </Button>
            </PurpleCircle>
          </CircleWrapper>
        </CircleContainer>

        <VisualWrapper>
          {activeComponent === "Visual" && (
            <div ref={visualWrapperRef}>
              {" "}
              <Visual />
              <Slideshow visualComponents={visualComponents} />
            </div>
          )}
        </VisualWrapper>
        <AuditoryWrapper>
          {activeComponent === "Auditory" && (
            <div ref={auditoryWrapperRef}>
              {" "}
              <Auditory />
              <Slideshow auditoryComponents={auditoryComponents} />
            </div>
          )}
        </AuditoryWrapper>
        <InteractiveWrapper>
          {activeComponent === "Interactive" && (
            <div ref={interactiveWrapperRef}>
              {" "}
              <Interactive />
              <Slideshow interactiveComponents={interactiveComponents} />
            </div>
          )}
        </InteractiveWrapper>
      </Container>
    </>
  );
};

export default Services;
