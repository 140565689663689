import React from "react";
import styled from "styled-components";
import homepageSvg from "../../images/homepage.svg";

const Container = styled.section`
  width: 100%;
  height: calc(100vh - 100px);
  top: 0;
  /* aspect-ratio: 2 / 1; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 10% 0;
`;

const TextContainer = styled.div`
  display: flex;
  width: 100%;
  user-select: none;
`;

// const VideoContainer = styled.div`
//   width: 100%;
// `;

const Image = styled.img`
  width: 100%;
`;

const HomeScreen = () => {
  return (
    <Container className="panel">
      <TextContainer>
        <Image src={homepageSvg} alt="" />
      </TextContainer>
      {/* <VideoContainer></VideoContainer> */}
    </Container>
  );
};

export default HomeScreen;
