import styled from 'styled-components';
import { NavLink as Link } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';
import BackgroundImage from '../../images/Background/noisebg-300x300.jpg';

export const SidebarContainer = styled.aside`
  background-image: url(${BackgroundImage});
  position: fixed;
  z-index: 998;
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0;
  transition: 0.3s ease-in-out;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 92%);
  opacity: ${({ isOpen }) => (isOpen ? '100%' : '0')};
  top: ${({ isOpen }) => (isOpen ? '0' : '-80%')};

  @media screen and (max-width: 320px) {
    height: 94%;
    top: 20px;
    top: ${({ isOpen }) => (isOpen ? '20' : '-94%')};
  }
`;

export const CloseIcon = styled(FaTimes)`
  color: #efefef;
  opacity: 0;
`;

export const Icon = styled.div`
  position: absolute;
  top: 1.4rem;
  right: 1.5rem;
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
`;

export const SidebarWrapper = styled.div`
  color: #fff;
`;

export const SidebarMenu = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;
  text-align: center;

  @media screen and (max-width: 320px) {
    gap: 2rem;
  }
`;

export const NavLink = styled(Link)`
  font-family: DINProMedium;
  letter-spacing: 0.02em;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  list-style: none;
  transition: 0.2s ease-in-out;
  color: #707070;
  cursor: pointer;

  &.active {
    color: #efefef;
    background: #f94819;
    height: fit-content;
    width: fit-content;
    padding: 4px 6px;
    margin: -4px -6px;
    transition: background 0.2s ease-in-out;
  }

  &:hover {
    color: #efefef;
    transition: 0.2s ease-in-out;
  }
`;
