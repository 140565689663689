import React from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import { useEffect } from 'react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
`;

const ShapeShifter = styled.div`
  --setBorderRadius: 0%;
  aspect-ratio: 1 / 1;
  width: 26%;
  border-radius: 0%;
  margin: auto;
  border: 3px solid #efefef;
  transform: translate(-135%, 0) rotate(0);
  display: inline-block;
  position: absolute;
  background: transparent;
  border: none;
  mix-blend-mode: screen;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    padding: 2px;
    border-radius: var(--setBorderRadius);
    transform: rotate(0);
    filter: blur(1px);
    background: linear-gradient(45deg, #f94819, #efefef),
      linear-gradient(0deg, #ffffff, #ffffff);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
`;

const ShapeShift = () => {
  useEffect(() => {
    const tl = gsap.timeline({});
    ScrollTrigger.create({
      trigger: '.shape',
      toggleActions: 'restart pause restart none',
      animation: tl,
    });
    tl.from('.shape', {
      '--setBorderRadius': '0%',
      x: '-130%',
      rotation: 0,
      duration: 0,
      delay: 0,
    });
    tl.to('.shape', {
      '--setBorderRadius': '50%',
      x: '100%',
      rotation: 360,
      duration: 2,
      delay: 2,
    });
    tl.to('.shape', {
      '--setBorderRadius': '0%',
      x: '-130%',
      rotation: 0,
      duration: 2,
      delay: 2,
    });

    return () => {
      ScrollTrigger.killAll();
    };
  }, []);

  return (
    <Container>
      <ShapeShifter className="shape" />
    </Container>
  );
};

export default ShapeShift;
